import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api/api.service';
import { UntypedFormGroup, Validators, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { NzMessageService } from 'ng-zorro-antd/message';
import { HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.scss']
})
export class PasswordResetComponent implements OnInit {
  
  validateForm: UntypedFormGroup;
  resetToken: string;
  buttonLoading = false;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private fb: UntypedFormBuilder,
    private message: NzMessageService,
    private router: Router
  ) { }

  submitForm(): void {
    this.buttonLoading = true;
    Object.keys(this.validateForm.value).forEach(key => {
      this.validateForm.controls[key].markAsDirty();
      this.validateForm.controls[key].updateValueAndValidity();
    });
    if (this.validateForm.valid) {
      let header = new HttpHeaders();
      header = header.append('token', this.resetToken);
      this.api.post<any>('users/password-reset', this.validateForm.value, header).toPromise()
      .then((result) => {
        this.buttonLoading = false;
        this.message.success('Hasło zostało zmienione, teraz możesz się zalogować!');
        this.router.navigate(['/sign-in']);
      })
      .catch((err) => {
        this.buttonLoading = false;
        console.error(err);
      });
    } else {
      this.buttonLoading = false;
    }
  }

  confirmationValidator = (control: UntypedFormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  }

  ngOnInit() {
    this.resetToken = this.route.snapshot.paramMap.get('token');
    this.validateForm = this.fb.group({
      password: [null, [Validators.required, Validators.minLength(8)]],
      passwordrepeat: [null, [Validators.required, this.confirmationValidator]],
    });
  }
}
