import { CommonModule } from "@angular/common";
import { Component } from "@angular/core";
import { RouterModule } from "@angular/router";
import { EventSessionView } from "@api/seasons/model";
import { SeasonsApiService } from "@api/seasons/seasons-api.service";
import { selectSeasonById } from "@api/seasons/state/seasons.selectors";
import { Store } from "@ngrx/store";
import { NzButtonModule } from "ng-zorro-antd/button";
import { NzGridModule } from "ng-zorro-antd/grid";
import { NzLayoutModule } from "ng-zorro-antd/layout";
import { NzTableModule } from "ng-zorro-antd/table";
import { BehaviorSubject, of } from "rxjs";
import {
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { environment } from "src/environments/environment";
import { MpdmDestroyComponent } from "../modules/core/components/mpdm-destroy/mpdm-destroy.component";
import { DriverStandings } from "../modules/league/models/driver-standings";

const CURRENT_SEASON = 50;
const EVENT_SKELETON = Array(4).map(() => ({ name: "" } as EventSessionView));

@Component({
  selector: "pescp-drivers-classification",
  templateUrl: "./drivers-classifications.component.html",
  styleUrls: ["./drivers-classifications.component.scss"],
  standalone: true,
  imports: [
    CommonModule,
    NzTableModule,
    NzLayoutModule,
    NzGridModule,
    NzButtonModule,
    RouterModule,
  ],
})
export class DriversClassificationsComponent extends MpdmDestroyComponent {
  constructor(
    private readonly store: Store,
    private readonly restService: SeasonsApiService
  ) {
    super();
  }

  private readonly standingsLoading = new BehaviorSubject<boolean>(true);
  readonly standingsLoading$ = this.standingsLoading.asObservable();

  readonly today = new Date();
  readonly staticUrl = environment.staticUrl;
  readonly currentSeason = CURRENT_SEASON;
  readonly season$ = this.store
    .select(selectSeasonById(CURRENT_SEASON))
    .pipe(filter(Boolean));

  readonly seasonName$ = this.season$.pipe(map((season) => season.name));

  readonly events$ = of(EVENT_SKELETON).pipe(
    switchMap(() => this.season$.pipe(map((season) => season.events)))
  );

  readonly standings$ = of([] as DriverStandings[]).pipe(
    switchMap(() =>
      this.season$.pipe(
        distinctUntilChanged(),
        tap(() => this.standingsLoading.next(true)),
        switchMap((season) =>
          this.restService.getDriverStandingsBySeasonId(season.id).pipe(
            map((standings) => standings.slice(0, 9)),
            tap(() => this.standingsLoading.next(false))
          )
        )
      )
    )
  );
}
