<div class="forgot-password-bg">
    <div class="app-layout-outer">
        <div class="app-layout inner-content login-form">
            <div class="section-title">
                <h1 class="subsection-heading red centered">Zapomniałem hasła</h1>
                <p>Wprowadź nowe hasło i zatwierdź zmianę</p>
            </div>
            <form nz-form [formGroup]="validateForm" class="" (ngSubmit)="submitForm()">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="passwordTip">
                        <nz-input-group>
                            <input type="password" nz-input formControlName="password" placeholder="Hasło" />
                        </nz-input-group>
                        <ng-template #passwordTip let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                Wprowadź hasło
                            </ng-container>
                            <ng-container *ngIf="control.hasError('minlength')">
                                Hasło musi zawierać co najmniej 8 znaków
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="passwordrepeatTip">
                        <nz-input-group>
                            <input type="password" nz-input formControlName="passwordrepeat"
                                placeholder="Powtórz hasło" />
                        </nz-input-group>
                        <ng-template #passwordrepeatTip let-control>
                            <ng-container *ngIf="control.hasError('required')">
                                Powtórz hasło!
                            </ng-container>
                            <ng-container *ngIf="control.hasError('confirm')">
                                Hasła są od siebie różne!
                            </ng-container>
                        </ng-template>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control>
                        <button nz-button class="btn btn-blue w-100" [nzLoading]="buttonLoading">Zatwierdź</button>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </div>
    </div>
</div>