import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-at',
  templateUrl: './at.component.html',
  styleUrls: ['./at.component.scss']
})
export class AtComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
