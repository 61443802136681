import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

/**
 * @deprecated
 */
@Injectable({
  providedIn: "root",
})
export class ApiService {
  backendUrl: string = environment.backendUrl;

  constructor(private http: HttpClient) {}

  /**
   * @deprecated
   * @param headers
   * @returns
   */
  addDefaultHeaders(headers?: HttpHeaders) {
    return headers;
  }

  get<T>(url: string, headers?: HttpHeaders) {
    headers = this.addDefaultHeaders(headers);
    return this.http.get<T>(this.backendUrl + url, {
      headers,
    });
  }

  post<T>(url: string, payload: any, headers?: HttpHeaders) {
    headers = this.addDefaultHeaders(headers);
    return this.http.post<T>(this.backendUrl + url, payload, {
      headers,
    });
  }

  put<T>(url: string, payload: any, headers?: HttpHeaders) {
    headers = this.addDefaultHeaders(headers);
    return this.http.put<T>(this.backendUrl + url, payload, {
      headers,
    });
  }

  delete<T>(url: string, headers?: HttpHeaders) {
    headers = this.addDefaultHeaders(headers);
    return this.http.delete<T>(this.backendUrl + url, {
      headers,
    });
  }
}
