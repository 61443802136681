import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit, isDevMode } from "@angular/core";
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { NzMessageService } from "ng-zorro-antd/message";
import { ApiService } from "../../services/api/api.service";

@Component({
  selector: "app-sign-up",
  templateUrl: "./sign-up.component.html",
  styleUrls: ["./sign-up.component.scss"],
})
export class SignUpComponent implements OnInit {
  validateForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private api: ApiService,
    private message: NzMessageService,
    private router: Router
  ) {}

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }
    if (this.validateForm.valid) {
      this.api
        .post("users/add", this.validateForm.value)
        .toPromise()
        .then((response) => {
          if (isDevMode()) {
            console.log(response);
          }
          this.message.success(
            "Konto zostało utworzone. Możesz się teraz zalogować."
          );
          this.router.navigate(["/sign-in"]);
        })
        .catch((error: HttpErrorResponse) => {
          if (error.status === 406) {
            this.validateForm.get("email").setErrors({
              email_taken: true,
            });
          }
          if (isDevMode()) {
            console.log(error);
          }
        });
    }
    if (isDevMode()) {
      console.log(this.validateForm);
    }
  }

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      firstName: [null, [Validators.required]],
      lastName: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required, Validators.minLength(8)]],
      passwordrepeat: [null, [Validators.required, this.mustMatchValidator()]],
      regulationaccept: [false, [Validators.required, Validators.requiredTrue]],
      privacyaccept: [false, [Validators.required, Validators.requiredTrue]],
      // contractaccept: [false, [Validators.required, Validators.requiredTrue]],
      newsletteraccept: [false],
    });
  }

  openInNewTab(link: string) {
    window.open(link, "_blank").focus();
  }

  private mustMatchValidator(): ValidatorFn {
    return (confirmPasswordCtrl: FormControl): ValidationErrors | null => {
      if (!this.validateForm) return null;
      const passwordCtrl = this.validateForm.controls.password;

      return confirmPasswordCtrl.value === passwordCtrl.value
        ? null
        : { passwordsMustMatch: true };
    };
  }
}
